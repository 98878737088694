import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux"
import Layout from './Layout/index.jsx';
import { BrowserRouter } from "react-router-dom";
import store from "./store"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <BrowserRouter>
            {/* <React.StrictMode> */}
                <Layout />
            {/* </React.StrictMode> */}
        </BrowserRouter>
    </Provider>

);

