import axios from "axios";


axios.defaults.headers.post['Content-Type'] = 'application/json';


axios.create({
    timeout: 5000,
});

const request = ({
     url,
     method = 'get',
     headers,
     params,
     data,
    responseType,
     withCredentials = true
 }) => {
    function linkLogin (err,reject) {
        if ( err.response.data.message === 'Unauthenticated.' ) {
            window.location.replace("/login")
            return;
        }
         reject(err)
    }
    return new Promise(( resolve, reject )=>{
        switch (method) {
            case 'post':
                axios({
                    url: url,
                    method,
                    headers,
                    params,
                    data,
                    responseType,
                    withCredentials :true,
                }).then ( res => resolve(res) )
                    .catch(err=>linkLogin(err,reject))
                break;

            default:
                axios({
                    url: url,
                    method,
                    headers,
                    params,
                    responseType,
                    withCredentials : true
                }).then( res => resolve(res))
                    .catch( err => linkLogin(err,reject) )
                break;
        }
        //2. 拦截器（去axios.js官网上去找拦截器）
        // 添加请求拦截器
        axios.interceptors.request.use(function (config) {
            // 在发送请求之前做些什么
            return config;
        }, function (error) {
            // 对请求错误做些什么
            return Promise.reject(error);
        });

        // 添加响应拦截器
        axios.interceptors.response.use(function (response) {
            // 对响应数据做点什么
            return response;
        }, function (error) {
            // console.log('对请求错误做些什么',error.response)
            // 对响应错误做点什么

            return Promise.reject(error);
        });
    })
}

export default request