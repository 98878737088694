import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    currentPage:1
}
const complaintListReducer = createSlice({
    name:'complaintListReducer',
    initialState,
    reducers:{
        setCurrentPage(state,action) { // 修改页数
            state.currentPage = action.payload
        }
    }
})

export const { setCurrentPage } = complaintListReducer.actions

export default complaintListReducer.reducer